@import "../include";

.container {
  margin-top: 100px;
  padding-bottom: 100px;

  @media #{$phone} {
    margin-top: 50px;
    padding-bottom: 50px;
  }
}

.content {
  @extend %content-container;
}

.topLine {
  width: 100%;
  height: 1px;
  background-color: #BEC0BF;

  @media #{$phone} {
    width: 100%;
  }
}

.topLine + .top {
  margin-top: 100px;

  @media #{$phone} {
    margin-top: 50px;
  }
}

.top {
  display: flex;
  justify-content: space-between;

  @media #{$phone} {
    flex-direction: column;
    justify-content: center;
  }
}

.contactsTitle, .linksTitle, .socialsTitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  color: #282828;

  @media #{$phone} {
    font-size: 25px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }
}

.contactsTitle + .contact {
  margin-top: 40px;

  @media #{$phone} {
    margin-top: 24px;
  }
}

.contact {
  display: flex;
  text-decoration: none;

  & + & {
    margin-top: 50px;

    @media #{$phone} {
      margin-top: 28px;
    }
  }

  @media #{$phone} {
    flex-direction: column;
    align-items: center;
  }
}

.contactIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 58px;
  height: 58px;

  background: #4690FF;
  border-radius: 13px;

  @media #{$phone} {
    width: 53px;
    height: 53px;
  }
}

.contactIcon {
  &_phone {
    width: 32px;
    height: 32px;
  }

  &_pin {
    width: 28px;
    height: 32px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.contactIconContainer + .contactText {
  @media #{$tablet-and-desktop} {
    margin-left: 18px;
  }
}

.contactText {
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  color: #282828;
  letter-spacing: 0.008em;
  white-space: pre-line;

  @media #{$phone} {
    font-size: 18px;
    line-height: 18px;
  }
}

.contact_phone .contactText {
  padding-top: 14px;

  @media #{$phone} {
    padding-top: 18px;
  }
}

.contact_address .contactText {
  padding-top: 4px;

  @media #{$phone} {
    padding-top: 18px;
    text-align: center;
  }
}

.contacts + .linksAndSocials {
  @media #{$phone} {
    margin-top: 52px;
  }
}

.linksAndSocials {
  display: flex;

  @media #{$tablet-and-desktop} {
    flex-shrink: 0;
    width: 434px;
  }

  @media #{$phone} {
    flex-direction: column;
  }
}

.links {
  width: 100%;

  @media #{$tablet-and-desktop} {
    flex-shrink: 0;
    max-width: 200px;
  }

  @media #{$phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.linksTitle + .linksList {
  margin-top: 20px;

  @media #{$phone} {
    margin-top: 24px;
  }
}

.linksList {
  display: flex;
  flex-direction: column;
}

.link {
  font-size: 22px;
  font-weight: 500;
  line-height: 50px;
  color: #282828;
  text-decoration: none;

  transition: color 125ms linear;

  &:hover, &:active {
    color: #4690FF;
  }

  @media #{$phone} {
    font-size: 18px;
    text-align: center;
  }
}

.links + .socials {
  @media #{$tablet-and-desktop} {
    margin-left: 55px;
  }

  @media #{$phone} {
    margin-top: 38px;
  }
}

.socials {
  @media #{$phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.socialsTitle + .socialsList {
  margin-top: 38px;

  @media #{$phone} {
    margin-top: 26px;
  }
}

.socialsList {
  display: flex;
}

.socialButton {
  transform: translateY(0);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 53px;
  height: 53px;

  background: #4690FF;
  border-radius: 9px;
  box-shadow: 0 0 0 rgb(0 0 0 / 0%);

  transition: transform 125ms ease-in-out, box-shadow 125ms linear;

  & + & {
    margin-left: 11px;
  }

  @media #{$tablet-and-desktop} {
    &:hover {
      transform: translateY(-11px);
      box-shadow: 0 12px 15px rgb(133 133 133 / 30%);
    }
  }
}

.socialIcon {
  &_linkedIn {
    width: 27px;
  }

  &_twitter {
    width: 27px;
  }

  &_facebook {
    width: 15px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.top + .bottom {
  margin-top: 90px;

  @media #{$phone} {
    margin-top: 51px;
  }
}

.bottom {
  display: flex;
  justify-content: center;
}

.copyright {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #282828;
}

.bubble {
  position: absolute;
  bottom: -1300px;
  left: 30%;
  transform: rotate(91.74deg);

  width: 990.35px;
  height: 2449.04px;

  opacity: 0.6;
  background: radial-gradient(47.24% 47.24% at 49.9% 52.76%, rgb(242 47 176 / 40%) 0%, rgb(245 138 37 / 0%) 100%);

  @media #{$phone} {
    display: none;
  }
}

.bubbleMobile {
  position: absolute;
  bottom: -550px;
  left: 50%;
  transform: rotate(91.74deg);

  width: 685.79px;
  height: 876.44px;
  margin-left: -50%;

  opacity: 0.65;
  background: radial-gradient(47.24% 47.24% at 49.9% 52.76%, rgb(242 47 176 / 40%) 0%, rgb(245 138 37 / 0%) 100%);

  @media #{$tablet-and-desktop} {
    display: none;
  }
}

