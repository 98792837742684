@import "../../include";

.container {
  @extend %content-container;

  position: relative;
  margin-top: 50px;
}

.bubbleRed {
  position: absolute;
  top: -80px;
  right: -282px;

  width: 498px;
  height: 520px;

  background: radial-gradient(48.35% 48.16% at 46.6% 51.84%, rgb(255 123 202 / 30%) 0%, rgb(255 123 202 / 0%) 82.35%);
}

.bubbleBlue {
  position: absolute;
  top: 130px;
  right: -334px;

  width: 477px;
  height: 492px;

  background: radial-gradient(50% 50% at 50% 50%, rgb(66 221 255 / 30%) 0%, rgb(17 112 255 / 0%) 93.57%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menuContainer {
  & > div + div {
    margin-top: 31px;
  }
}

.menuLink {
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
  color: #282828;
  text-decoration: none;
}

.menuContainer + .contactBtn {
  margin-top: 47px;
}

.contactBtn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 214px;
  min-height: 64px;
  padding: 4px;

  color: #4690FF;
  text-decoration: none;

  border: 2px solid currentcolor;
  border-radius: 12px;
  box-shadow: 0 0 5px -2px transparent;

  transition: 125ms linear box-shadow;

  &:active {
    box-shadow: 0 0 8px rgb(70 144 255 / 97%)
  }

  span {
    font-family: Satoshi, sans-serif;
    font-size: 18px;
    font-weight: 900;
    font-style: normal;
    line-height: 24px;
    text-align: center;
  }

  span + svg {
    margin-left: 8px;
  }
}

.contactBtn + .socials {
  margin-top: 47px;
}

.socialsTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #293338;
}

.socialsTitle + .socialsList {
  margin-top: 22px;
}

.socialsList {
  display: flex;
}

.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  background: #4690FF;
  border-radius: 9px;

  & + & {
    margin-left: 11px;
  }
}

.socialIcon {
  &_linkedIn {
    width: 21px;
  }

  &_twitter {
    width: 21px;
  }

  &_facebook {
    width: 11px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.socials + .contacts{
  margin-top: 47px;
}

.contactsTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #293338;
}

.contactsTitle + .contactsList {
  margin-top: 22px;
}

.contact {
  display: flex;
  text-decoration: none;

  & + & {
    margin-top: 21px;
  }
}

.contactIconContainer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  background: #4690FF;
  border-radius: 9px;
}

.contactIcon {
  &_phone {
    width: 21px;
    height: 21px;
  }

  &_pin {
    width: 18px;
    height: 22px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.contactIconContainer + .contactText {
  margin-left: 16px;
}

.contactText {
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #11142D;
  letter-spacing: 0.1px;
}
