@import "../../include";

.container {
  position: relative;

  input, textarea {
    width: 100%;

    font-family: Satoshi, arial, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    color: #333;
    letter-spacing: 0.008em;

    background: #FFF;
    border: 1px solid #BEC0BF;
    border-radius: 8px;

    transition: border 75ms linear;

    &::placeholder {
      font-weight: 500;
      color: #92929D;
    }

    &:focus, &:focus-visible, &:active {
      border: 1px solid #1573FF;
      outline: unset;
    }

    @media #{$phone} {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;

      border-color: #eaeaea;
      border-width: 0.3px;
    }
  }

  input {
    height: 73px;
    padding: 23px 60px 23px 23px;

    &[data-invalid], &:invalid {
      color: #F00;
      border: 1px solid #F00;
    }

    @media #{$phone} {
      padding: 27px 60px 23px 23px;
    }
  }

  textarea {
    resize: none;
    height: 261px;
    padding: 23px;

    @media #{$phone} {
      min-height: 263px;
      padding: 25px 23px 23px;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);

    width: 24px;

    opacity: 0;

    // height: 21px;
    transition: opacity 150ms linear;
  }

  &.hasError svg {
    opacity: 1;
  }

  .errorText {
    position: absolute;
    top: calc(100% + 5px);
    left: 10px;

    color: red;

    opacity: 0;

    transition: opacity 150ms linear;
  }

  &.hasError .errorText {
    opacity: 1;
  }

  /* input[data-invalid] + svg, input:invalid + svg {

  } */
}
