@import "../include";

.headerBox {
  position: sticky;
  z-index: 999;
  top: 0;

  background-color: transparent;
  box-shadow: 0 4px 54px rgb(0 0 0 / 0%);

  transition: box-shadow 150ms linear, background-color 350ms linear;

  &[unwrapper-node-type] { // Prevent hide container on unwrap menu
    display: block !important;
  }

  &.sticky {
    background-color: $colorBackground;
    box-shadow: 0 4px 54px rgb(0 0 0 / 11%);
  }

  @media #{$phone} {
    background-color: $colorBackground; // Because mobile recalculate layers only after scroll
  }
}

.headerContainer {
  @extend %content-container;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 162px;


  @media #{$phone} {
    min-height: 78px;
    padding-top: 10px;
  }
}

.leftContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  width: 187px;

  svg {
    width: 100%;
    height: auto;
  }

  @media #{$phone} {
    svg {
      width: auto;
      height: 39px; // Prevent bottom of svg corrupting
    }
  }
}

.menuContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  & > div + div {
    margin-left: 39px;
  }

  @media #{$phone} {
    display: none;
  }
}

.menuLink {
  position: relative;

  display: flex;
  flex-shrink: 0; // Prevent new lines in menu
  align-items: center;
  justify-content: center;

  min-height: 48px;
  margin-bottom: 4px;
  padding: 5px;

  font-family: Satoshi, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  line-height: 22px;
  color: #282828;
  text-decoration: none;

  transition: 125ms linear color;

  &::after {
    $h: 6px;

    content: '';

    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);

    width: $h;
    height: $h;

    opacity: 0;
    background-color: currentcolor;
    border-radius: 100%;
  }

  &.menuLinkActive {
    color: #4690FF;
  }

  &:hover {
    color: #4690FF;
  }

  &.menuLinkActive::after {
    opacity: 1;
  }
}

.rightContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.contactBtn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 218px;
  min-height: 68px;
  padding: 4px;

  color: #4690FF;
  text-decoration: none;

  background: transparent;
  border: 2px solid currentcolor;
  border-radius: 14px;
  box-shadow: 0 0 5px -2px transparent;

  transition: 125ms linear box-shadow;

  &:hover {
    box-shadow: 0 0 8px rgb(70 144 255 / 97%);
  }

  &:active {
    box-shadow: 0 0 8px rgb(70 144 255 / 97%)
  }

  span {
    font-family: Satoshi, sans-serif;
    font-size: 22px;
    font-weight: 900;
    font-style: normal;
    line-height: 24px;
    text-align: center;
  }

  span + svg {
    margin-left: 9px;
  }

  @media #{$phone} {
    display: none;
  }
}

.burgerButton {
  cursor: pointer;

  display: none;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  background: transparent;
  border: none;

  svg {
    display: none;
    color: #000;
  }

  @media #{$phone} {
    display: flex;
  }

  @media #{$phone} {
    margin-right: -10px;
  }
}

.burgerIcon {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  width: 30px;
  height: 22px;


  i {
    transform-origin: center right;

    display: block;

    width: 100%;
    height: 3px;

    color: #000;

    background-color: currentcolor;
    border: 0 none;
    border-radius: 3px;

    transition: transform 150ms ease-out;
  }

  i:nth-child(2) {
    align-self: flex-end;
    width: 50%;
  }

  i:nth-child(1), i:nth-child(3) {
    transform-origin: right center;
    transform: translate(0, 0) rotate(0);
  }

  // stylelint-disable-next-line no-duplicate-selectors
  i:nth-child(2) {
    transform-origin: 20% center;
    transform: scaleX(1);
  }
}

.mobileMenuOpened {
  i:nth-child(1) {
    transform: translate(0, -1px) rotate(-45deg);
  }

  i:nth-child(2) {
    transform: scaleX(0);
  }

  i:nth-child(3) {
    transform: translate(0, 1px) rotate(45deg);
  }
}

.mobileMenu {
  display: none;
}

.mobileMenu[unwrapper-node-type] {
  display: block;
}
