@import "../include";

.container {
  @extend %content-container;
}

.titleAndDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
    color: #282828;
    text-align: center;
    text-transform: capitalize;

    i {
      color: #4690FF;
    }

    @media #{$phone} {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h2 + p {
    margin-top: 29px;

    @media #{$phone} {
      margin-top: 26px;
    }
  }

  p {
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    color: #282828;
    text-align: center;

    @media #{$phone} {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: unset;
    }
  }
}

.titleAndDescription + .form {
  margin-top: 62px;

  @media #{$phone} {
    margin-top: 40px;
  }
}

.form {
  width: 100%;
  max-width: 920px;
  margin-right: auto;
  margin-left: auto;
}

.formInputsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: -17px -16px;
}

.formInput {
  width: 50%;
  padding: 17px 16px;

  &_message {
    width: 100%;
  }

  span {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: #282828;
    letter-spacing: 0.1px;

    @media #{$phone} {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }
  }

  & > span + * {
    margin-top: 8px;

    @media #{$phone} {
      margin-top: 11px;
    }
  }

  @media #{$phone} {
    width: 100%;
  }
}

.formInput_firstName,
.formInput_lastName {
  span, input {
    @media #{$tablet-and-desktop} {
      font-size: 20px;
    }
  }
}

.formInputsContainer + .submitButtonContainer {
  margin-top: 32px;

  @media #{$phone} {
    margin-top: 29px;
  }
}

.submitButtonContainer {
  display: flex;
  justify-content: center;
}

.submitButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 230px;
  height: 73px;

  font-family: Satoshi, arial, sans-serif;
  font-size: 22px;
  font-weight: 900;
  line-height: 30px;
  color: #FFF;
  text-align: center;

  background: #4690FF;
  border: unset;
  border-radius: 12px;

  transition: 125ms linear background;

  &:hover {
    background: #1573FF;
  }

  &:active {
    background: #1573FF;
  }

  @media #{$tablet-and-desktop} {
    padding: 14px 24px 10px;
  }

  @media #{$phone} {
    font-size: 20px;
    font-weight: 700;
  }
}
